const Utils = require('./utils');
var queryArray = Utils.queryArray;

Utils.touch = {
  isSupported: "ontouchstart" in window || navigator.maxTouchPoints,
  isDragging: false
};

Utils.ready(function() {
  document.body.addEventListener("touchmove", function() {
    Utils.touch.isDragging = true;
  });
  document.body.addEventListener("touchstart", function() {
    Utils.touch.isDragging = false;
  });
});

Utils.supports = {
  pointerEvents: function() {
    var style = document.createElement("a").style;
    style.cssText = "pointer-events:auto";
    return style.pointerEvents === "auto";
  }()
};

function siteNavDropdowns(e) {
  var _this = this;
  this.container = document.querySelector(e);
  this.mobileNav = document.querySelector("a.root-link.mobile-menu");
  this.nav = this.container.querySelector(".site-navigation");
  this.dropdownContainer = this.container.querySelector(".dropdown-container");
  this.dropdownLinks = Utils.queryArray(".has-dropdown", this.nav);
  this.dropdownSections = Utils.queryArray(".dropdown-section", this.container).map(function(e) {
    return {
      el: e,
      name: e.getAttribute("data-dropdown"),
      content: e.querySelector(".dropdown-content")
    }
  });

  var pointerEvent = window.PointerEvent ? {
    end: "pointerup",
    enter: "pointerenter",
    leave: "pointerleave"
  } : {
    end: "touchend",
    enter: "mouseenter",
    leave: "mouseleave"
  };

  this.dropdownLinks.forEach(function(el, i) {
    el.addEventListener(pointerEvent.end, function(e) {
      e.preventDefault();
      e.stopPropagation();
      _this.toggleDropdown(el);
    });
    el.addEventListener(pointerEvent.enter, function(e) {
      if (e.pointerType == "touch") return;
      _this.stopCloseTimeout();
      _this.openDropdown(el);
    });
    el.addEventListener(pointerEvent.leave, function(e) {
      if (e.pointerType == "touch") return;
      _this.startCloseTimeout();
    });
  });

  this.dropdownContainer.addEventListener(pointerEvent.end, function(e) {
    e.stopPropagation();
  });
  this.dropdownContainer.addEventListener(pointerEvent.enter, function(e) {
    if (e.pointerType == "touch") return;
    _this.stopCloseTimeout();
  });
  this.dropdownContainer.addEventListener(pointerEvent.leave, function(e) {
    if (e.pointerType == "touch") return;
    _this.startCloseTimeout();
  });
  document.body.addEventListener(pointerEvent.end, function(e) {
    Utils.touch.isDragging || _this.closeDropdown();
  });
};

siteNavDropdowns.prototype.openDropdown = function(link) {
  var _this = this;
  if (_this.activeDropdown === link) return;
  _this.container.classList.add("overlay-active");
  _this.container.classList.add("dropdown-active");
  _this.activeDropdown = link;
  _this.dropdownLinks.forEach(function(link){
    link.classList.remove("active");
  });
  link.classList.add("active");

  var dropdownName = link.getAttribute("data-dropdown"),
      dir = "left", height;
  
    _this.dropdownSections.forEach(function(section) {
    section.el.classList.remove("active");
    section.el.classList.remove("left");
    section.el.classList.remove("right");
    if (section.name == dropdownName) {
      section.el.classList.add("active");
      dir = "right";
      width = section.content.offsetWidth;
      height = section.content.offsetHeight;
    } else {
      section.el.classList.add(dir);
    }
  });

  this.dropdownContainer.style.height = height + "px";
}

siteNavDropdowns.prototype.closeDropdown = function() {
  var _this = this;
  if (!this.activeDropdown) return;
  this.dropdownLinks.forEach(function(el) {
    el.classList.remove("active");
  });
  clearTimeout(this.enableTransitionTimeout);
  this.disableTransitionTimeout = setTimeout(function() {
    _this.container.classList.add("no-dropdown-transition");
  }, 50);
  this.container.classList.remove("overlay-active");
  this.container.classList.remove("dropdown-active");
  this.activeDropdown = undefined;

  this.dropdownContainer.style.height = 0 + "px";
};

siteNavDropdowns.prototype.toggleDropdown = function(el) {
  this.activeDropdown === el ? this.closeDropdown() : this.openDropdown(el);
};

siteNavDropdowns.prototype.startCloseTimeout = function() {
  var _this = this;
  this.closeDropdownTimeout = setTimeout(function() {
    _this.closeDropdown();
  }, 50);
};

siteNavDropdowns.prototype.stopCloseTimeout = function() {
  clearTimeout(this.closeDropdownTimeout);
};



function closeMobileSubMenus(subMenuOpen, dropdowns, dropdownLinks){
  if (subMenuOpen) {
    dropdowns.forEach(function(d){
      d.classList.remove(activeClass);
    });
    dropdownLinks.forEach(function(l){
      l.classList.remove(activeClass);
    });
  }
  return false;
}

function siteNavPopup(e) {
  var pointerEvent = Utils.touch.isSupported ? "touchend" : "click";
  
  var activeClass = "popup-active";
  var closeButton = document.querySelector("a.root-link.mobile-menu");
  var mobileNav = document.querySelector("nav.mobile-navigation");
  var popup = document.querySelector(".mobile-navigation-popup");
  var siteHeader = document.querySelector(".site-header");
  var dropdownLinks = Utils.queryArray(".has-mobile-dropdown");
  var dropdowns = Utils.queryArray(".mobile-navigation-popup .dropdown-section");

  closeButton.addEventListener(pointerEvent, function(e){
    if (popup.classList.contains(activeClass)) {
      mobileNav.classList.remove(activeClass);
      popup.classList.remove(activeClass);
      siteHeader.classList.remove("mobile-nav-active");
    } else {
      subMenuOpen = true;
      mobileNav.classList.add(activeClass);
      popup.classList.add(activeClass);
      siteHeader.classList.add("mobile-nav-active");

      var pointerEvent = window.PointerEvent ? {
        end: "pointerup",
        enter: "pointerenter",
        leave: "pointerleave"
      } : {
        end: "touchend",
        enter: "mouseenter",
        leave: "mouseleave"
      };
    
      dropdownLinks.forEach(function(el) {
        el.addEventListener(pointerEvent.end, function(e) {
          // Active on a click
          closeAllPopups(activeClass);
          var dataType = el.getAttribute("data-dropdown");
          var dropdown = document.querySelector(".mobile-navigation-popup .dropdown-section[data-dropdown='" + dataType + "']");
          if (dropdown.classList.contains(activeClass)) {
            el.classList.remove(activeClass);
            dropdown.classList.remove(activeClass);
          } else {
            el.classList.add(activeClass);
            dropdown.classList.add(activeClass);
          }
        });
        el.addEventListener(pointerEvent.enter, function(e) {
          // Active on a hover
          closeAllPopups(activeClass);
          var dataType = el.getAttribute("data-dropdown");
          var dropdown = document.querySelector(".mobile-navigation-popup .dropdown-section[data-dropdown='" + dataType + "']");
          if (dropdown.classList.contains(activeClass)) {
            dropdown.classList.remove(activeClass);
            el.classList.remove(activeClass);
          } else {
            dropdown.classList.add(activeClass);
            el.classList.add(activeClass);
          }
        });
        // el.addEventListener(pointerEvent.leave, function(e) {
        //   // If click to close the submenu
        //   if (e.pointerType == "touch") {
        //   } else {
        //     var dataType = el.getAttribute("data-dropdown");
        //   }
        //   // _this.startCloseTimeout();
        // });
      });
    }
  });
}

siteNavPopup.prototype.togglePopup = function(dataType) {
  var active = document.querySelector("a.has-dropdown[data-dropdown='"+ dataType +"'").classList.contains(this.activeClass);
  this.closeAllPopups(true);
  if (!active) {
    this.root.classList.add(this.activeClass);
  }
}

function closeAllPopups(activeClass) {
  var dropdownLinks = Utils.queryArray(".has-mobile-dropdown");
  var dropdowns = Utils.queryArray(".mobile-navigation-popup .dropdown-section");

  dropdowns.forEach(function(el){
    el.classList.remove(activeClass);
  });
  dropdownLinks.forEach(function(el){
    el.classList.remove(activeClass);
  });
}

module.exports = function() {
  new siteNavDropdowns(".site-header");
  new siteNavPopup(".site-header .mobile-navigation");
};

function closeMobileMenus(whiteWrapper){
  var mobileNav = document.querySelector(".mobile-navigation-popup");
  var mobileNavToggle = document.querySelector(".mobile-navigation");
  var mobileNavMenus = queryArray(".has-mobile-dropdown");
  var dropdowns = queryArray(".dropdown-section");

  whiteWrapper.classList.remove("mobile-nav-active");
  mobileNav.classList.remove("popup-active");
  mobileNavToggle.classList.remove("popup-active");
  mobileNavMenus.forEach(function(el) {
    el.classList.remove("popup-active");
  });
  dropdowns.forEach(function(el) {
    el.classList.remove("popup-active");
  });
}

var mobileNavActiveWidth = 865;

window.addEventListener("DOMContentLoaded", function(){
  var width = $(window).width();
  var whiteWrapper = document.querySelector(".site-header");
  if (width > mobileNavActiveWidth && whiteWrapper.classList.contains("mobile-nav-active")) {
    closeMobileMenus(whiteWrapper);
  }
});
window.addEventListener("resize", function(){
  var width = $(window).width();
  var whiteWrapper = document.querySelector(".site-header");
  if (width > mobileNavActiveWidth && whiteWrapper.classList.contains("mobile-nav-active")) {
    closeMobileMenus(whiteWrapper);
  }
});