var Utils = require("./utils");
var queryArray = Utils.queryArray;

var popup = document.getElementById("popup");
var popupButton = document.getElementById("popup-close");
var popupBool = localStorage.getItem("popup") || "false";
var pageContent = document.getElementById("content");

if (popup && popupButton) {
  if (popupBool && (popupBool == false || popupBool == "false")) {
    window.addEventListener("unload", function() {
      localStorage.setItem("popup", "false");
      popup.remove();
    });
    popupButton.addEventListener("click", function(e) {
      e.preventDefault();
      localStorage.setItem("popup", "false");
      pageContent.classList.remove("disable");
      popup.remove();
    });
    pageContent.classList.add("disable");
  } else {
    popup.remove();
    pageContent.classList.remove("disable");
  }
}


var spotlightItems = queryArray(".spotlight-item");
var spotlightItemsVertical = queryArray(
  ".spotlight-item[data-direction='Vertical']"
);
var spotlightSectionsHorizontal = queryArray(".section--images");
var spotlightItemsHorizontalSlide = queryArray(
  ".spotlight-item[data-direction='Horizontal Sliding']"
);
var spotlightItemsHorizontalSnap = queryArray(
  ".spotlight-item[data-direction='Horizontal Snapping']"
);
var setupNavigation = require("./navigation");
var reframe = require("reframe.js");

setupNavigation();

try {
  reframe("iframe:not([data-no-respond])");
} catch (err) {
  console.log("No iframes to resize on this page.");
}

var settingsBtn = document.querySelector(".btn-settings");
if (settingsBtn) {
  var settingsMenu = document.querySelector(".settings-menu");
  settingsBtn.addEventListener("click", function () {
    settingsBtn.classList.toggle("open");
    settingsMenu.classList.toggle("active");
  });
}

if (!document.referrer) {
  var cookiePopup = document.createElement("div");
  cookiePopup.id = "cookies";
  var text = document.createElement("div");
  text.classList.add("cookies-text");
  text.innerHTML =
    "This website uses cookies to ensure the best user experience and maintain authentication for our members. By continuing to browse this website, you consent to the use of cookies. We do not share any data outside of the Flexible Packaging Association. Review our policies further on our <a href='/policies'>Privacy Policies page</a>.";
  var cookiesBtn = document.createElement("div");
  cookiesBtn.id = "cookies-close";
  var container = document.createElement("div");
  container.classList.add("container");
  container.appendChild(text);
  container.appendChild(cookiesBtn);
  cookiePopup.appendChild(container);
  document.body.appendChild(cookiePopup);

  window.addEventListener("unload", function () {
    localStorage.setItem("cookies-popup", false);
    cookiePopup.remove();
  });
  cookiesBtn.addEventListener("click", function (e) {
    e.preventDefault();
    localStorage.setItem("cookies-popup", false);
    cookiePopup.remove();
  });
}

var stickyButton = document.querySelector("a.sticky-nav");
if (stickyButton) {
  stickyButton.style.left =
    "-" + Math.floor((stickyButton.getBoundingClientRect().height - 27) / 1.98);
}

window.addEventListener("DOMContentLoaded", function () {
  if (spotlightItemsVertical != null) {
    spotlightItemsVertical.forEach(function (item) {
      var spotlightHeader = item.querySelector("h4");
      if (spotlightHeader) {
        var headerWidth = spotlightHeader.getBoundingClientRect().width;
        var textWidth = spotlightHeader
          .querySelector("span")
          .getBoundingClientRect().width;
        spotlightHeader.style.transform =
          "translateX(-" + (headerWidth - textWidth) + "px";
        spotlightHeader.style.width = "100%";
      }
    });
  }
  if (spotlightSectionsHorizontal != null) {
    spotlightSectionsHorizontal.forEach(function (section) {
      var spotlightItemsHorizontalSlide = queryArray(
        ".spotlight-item[data-direction='Horizontal Sliding']",
        section
      );
      var spotlightItemsHorizontalSnap = queryArray(
        ".spotlight-item[data-direction='Horizontal Snapping']",
        section
      );
      if (spotlightItemsHorizontalSlide != null) {
        spotlightItemsHorizontalSlide.forEach(function (item, index) {
          var spotlightHeader = item.querySelector("h4");
          if (spotlightHeader) {
            var headerWidth = spotlightHeader.getBoundingClientRect().width;
            var textWidth = spotlightHeader
              .querySelector("h4 span")
              .getBoundingClientRect().width;
            if (index % 2 == 0)
              spotlightHeader.style.transform =
                "translateX(" + (headerWidth - textWidth) + "px";
            else
              spotlightHeader.style.transform =
                "translateX(-" + (headerWidth - textWidth) + "px";
            spotlightHeader.style.width = "100%";
          }
        });
      }
      if (spotlightItemsHorizontalSnap != null) {
        spotlightItemsHorizontalSnap.forEach(function (item) {
          var spotlightHeader = item.querySelector("h4");
          if (spotlightHeader) {
            var textWidth = Math.ceil(
              spotlightHeader.querySelector("span").getBoundingClientRect()
                .width
            );
            spotlightHeader.style.width = textWidth + "px";
          }
        });
      }
    });
  }
});
window.addEventListener("resize", function () {
  if (settingsBtn) {
    settingsBtn.classList.remove("open");
    settingsMenu.classList.remove("active");
  }
  if (spotlightItemsVertical != null) {
    spotlightItemsVertical.forEach(function (item) {
      var spotlightHeader = item.querySelector("h4");
      if (spotlightHeader) {
        var headerWidth = spotlightHeader.getBoundingClientRect().width;
        var textWidth = spotlightHeader
          .querySelector("span")
          .getBoundingClientRect().width;
        spotlightHeader.style.transform =
          "translateX(-" + (headerWidth - textWidth) + "px";
        spotlightHeader.style.width = "100%";
      }
    });
  }
  if (spotlightSectionsHorizontal != null) {
    spotlightSectionsHorizontal.forEach(function (section) {
      var spotlightItemsHorizontalSlide = queryArray(
        ".spotlight-item[data-direction='Horizontal Sliding']",
        section
      );
      if (spotlightItemsHorizontalSlide != null) {
        spotlightItemsHorizontalSlide.forEach(function (item, index) {
          var spotlightHeader = item.querySelector("h4");
          if (spotlightHeader) {
            var headerWidth = spotlightHeader.getBoundingClientRect().width;
            var textWidth = spotlightHeader
              .querySelector("span")
              .getBoundingClientRect().width;
            if (index % 2 == 0)
              spotlightHeader.style.transform =
                "translateX(" + (headerWidth - textWidth) + "px";
            else
              spotlightHeader.style.transform =
                "translateX(-" + (headerWidth - textWidth) + "px";
            spotlightHeader.style.width = "100%";
          }
        });
      }
    });
  }
});

window.addEventListener("scroll", function () {
  if (spotlightItems != null) {
    spotlightItems.forEach(function (item) {
      var rect = item.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;

      if (elemTop < window.innerHeight && elemBottom >= 0) {
        item.classList.add("slide-in");
      }
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (!document.body.classList.contains("nohero")) {
    var curvedSection = document.querySelector("section:nth-of-type(2)");
    if (curvedSection) curvedSection.classList.add("section--opening");
  }
});